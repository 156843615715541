<template>
  <div class="welfareSetMealList">
    <search-com  @search-btn="searchBtn"></search-com>
    <div class="nav_box">
      <welfare-breadcrumb :itemBreadcrumb="itemBreadcrumb"></welfare-breadcrumb>
    </div>
    <!-- 节日列表 -->
    <div class="holidy_box" v-for="(item,index) in mealList" :key="item.festivalId + index">
      <!-- 商品列表 -->
      <template>
        <div class="list_box" v-if="item.mealList.length > 0"> 
          <div class="header_box">
            <h3 class="title">{{item.festivalName}}</h3>
          </div>
          <good-list-com :list="item.mealList"></good-list-com>
          <!-- 加载更多 -->
          <div class="more_box" v-if="item.count > 8">
            <div v-if="item.flag" class="more_btn" @click="moreBtn(item.festivalId)">
              <span>查看更多</span>
              <span class="more_icon el-icon-arrow-down"></span>
            </div>
            <div v-else class="more_btn" @click="foldBtn(item.festivalId)">
              <span>折叠收起</span>
              <span class="more_icon el-icon-arrow-up"></span>
            </div>
          </div>
        </div>
        <div v-else> 
        </div>
      </template>
      
    </div>
    
    
  </div>
</template>

<script>
import GoodListCom from '@/components/welfareMallComp/GoodListCom'
import SearchCom from '@/components/welfareMallComp/SearchCom';
import WelfareBreadcrumb from '@/components/welfareMallComp/WelfareBreadcrumb'
import { getReqMealList, getReqMealListAll, getReqSearchName } from "@/network/welfareMall_api";
export default {
  name: 'WelfareSetMealList',
  components: {
    GoodListCom,
    WelfareBreadcrumb,
    SearchCom
  },
  data() {
    return {
      itemBreadcrumb: [
        {
          name: '福利商城',
          to: '/welfareHome'
        },
        {
          name: '员工N选1礼包',
        }
      ],
      mealList: [], // 礼包列表
      goodList: [], // 点击更多展示对应的数组
    };
  },
  computed:{
  },
  watch:{
  },
  created() {
    this.getMealList()
  },
  methods: {
    // 礼包列表
    async getMealList(){
      var res = await getReqMealList()
      if(res.isSuccess){
        var mealList = res.data
        mealList.forEach( item => {
          item.flag = true;
          item.mealList.forEach( item => {
            // type  0 礼包 1商品
            item.type = 0;
            item.id = item.mealID;
          })
        })
        this.mealList = mealList
      }else{
        this.$message.error(res.msg)
      }

    },
    // 点击 更多
    async moreBtn(id){
      var res = await getReqMealListAll(id)
      if(res.isSuccess){
        this.goodList = res.data
        this.mealList.forEach( item => {
          if(item.festivalId == id){
            item.mealList = this.goodList
          }
        })
        this.mealList.forEach( item => {
          if(item.festivalId == id){
            item.flag = false
          }
          item.mealList.forEach( item => {
            // type  0 礼包 1商品
            item.type = 0;
            item.id = item.mealID;
          })
        })
      }else{
        this.$message.error(res.msg)
      }
    },
    // 折叠按钮 该展开的收起来 
    foldBtn(id){
      this.mealList.forEach( item => {
        if(item.festivalId == id){
          item.flag = true
          item.mealList = item.mealList.splice(0,8)
        }
      })
    },
    // 点击搜索
    searchBtn(val){
      if(!val){
        return this.$message.info('请输入礼包名称');
      }
      document.cookie = "vueRouter="+ '/welfareSearchResult';
      this.$router.push({
        name: 'WelfareSearchResult',
        params: {
          value: val
        }
      })
    }
  },
};
</script>

<style scoped lang="less">
.welfareSetMealList{
  // width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  // 节日列表
  .holidy_box{
    width: 1200px;
    margin: 40px auto 0;
    // 商品列表
    .list_box{
      padding-bottom: 40px;
      // 头部
      .header_box{
        margin-bottom: 40px;
        text-align: center;
        background:url('../../assets/img/welfareMall/meal_festival_bg.png') center no-repeat;
        .title{
          font-size: 24px;
          font-weight: blod;
          color: #3C3C3C;
        }
      }
      // 加载更多
      .more_box{
        display: flex;
        justify-content: center;
        .more_btn{
          padding: 10px 23px;
          border: 1px solid #1890FF;
          border-radius: 20px;
          color: #999999;
          .more_icon{
            padding-left: 4px;
          }
          &:hover{
            background-color: #1890FF;
            color: #fff;
          }
        }
      }
    }
  }
}

</style>
